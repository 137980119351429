<template>
  <cs-dialog-form
    :title="title"
    :model="formModel"
    :rules="formRules"
    :action="submit"
  >
    <a-form-model-item label="用户名" prop="realName">
      <a-input v-model.trim="formModel.realName" :maxLength="20" />
    </a-form-model-item>
  </cs-dialog-form>
</template>
<script>
export default {
  props: {
    data: {},
  },
  data() {
    return {
      formModel: {
        realName: "",
        ...this.data,
      },
      formRules: {
        realName: [{ required: true, message: "请输入用户名" }],
      },
    };
  },
  computed: {
    title() {
      return `用户名信息`;
    },
  },
  created() {},
  methods: {
    async submit({ realName }) {
      return await this.api.service.uac_oapi_sso_user_updateUserInfo(
        {
          realName,
        },
        {}
      );
    },
  },
};
</script>