<template>
  <cs-page-user class="views-user-setting">
    <cs-panel-default title="账号设置" icon="icon-zhanghaoshezhi1">
      <div class="setting-panel">
        <a-row class="item">
          <a-col :span="3">
            <div class="user-head">
              <a-avatar :src="userInfo.headUrl" shape="square" />
              <span
                @click="headUrlEditClick"
                class="text-center position-absolute text-white cursor-pointer"
                >修改头像</span
              >
            </div>
          </a-col>
          <a-col :span="16">
            <p
              style="margin-top: 20px"
              class="cursor-pointer"
              @click="userEditClick"
            >
              用户名：{{ userInfo.realName }}
            </p>
            <p>注册时间：{{ userInfo.createDate }}</p>
          </a-col>
          <a-col :span="5" class="text-end">
            <!-- <a
              class="text-primary float-end"
              @click="userEditClick"
              style="margin-top: 35px"
              >修改</a
            > -->
          </a-col>
        </a-row>
        <a-row class="item">
          <a-col :span="3" class="fw-bold d-flex">
            <cs-icon name="icon-denglumima" />
            登录密码
          </a-col>
          <a-col :span="16">
            设置6~20位密码，不能连号和特殊符号；建议您定期更换密码
          </a-col>
          <a-col :span="5" class="text-end">
            <a-space size="middle" v-if="userInfo.passFalg">
              <span class="setting-icon">
                <cs-icon name="icon-yishezhi" size="14" />已设置
              </span>
              <a-divider type="vertical" />
              <router-link to="/user/resetPassword">修改</router-link>
            </a-space>
            <router-link v-else to="/user/resetPassword">设置密码</router-link>
          </a-col>
        </a-row>
        <a-row class="item">
          <a-col :span="3" class="fw-bold d-flex">
            <cs-icon name="icon-xiugaishouji" />
            修改手机</a-col
          >
          <a-col :span="16">
            您已绑定了手机
            <span class="text-primary">{{
              userInfo.userName | encryText
            }}</span>
            [您的手机可直接用户登录、找回密码等]
          </a-col>
          <a-col :span="5" class="text-end">
            <a-space size="middle">
              <span class="setting-icon">
                <cs-icon name="icon-yishezhi" size="14" />已设置
              </span>
              <a-divider type="vertical" />
              <router-link to="/user/setTel">修改</router-link>
            </a-space>
          </a-col>
        </a-row>
        <a-row class="item">
          <a-col :span="3" class="fw-bold d-flex">
            <cs-icon name="icon-zhuxiao" />
            注销账号
          </a-col>
          <a-col :span="16">
            账号注销成功后，其下所有服务、数据及隐私信息将会被删除并将无法恢复
          </a-col>
          <a-col :span="5" class="text-end">
            <router-link to="/user/logout">注销</router-link>
          </a-col>
        </a-row>
      </div>
    </cs-panel-default>
  </cs-page-user>
</template>
<script>
import HeadUrlEdit from "@/components/user/HeadUrlEdit";
import NickNameEdit from "@/components/user/NickNameEdit";
import { mapState } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapState({ userInfo: (state) => state.store.userInfo }),
  },
  inject: ["loadUserInfo"],
  created() {},
  methods: {
    userEditClick() {
      this.api.component(NickNameEdit).then(() => {
        this.loadUserInfo();
      });
    },
    headUrlEditClick() {
      const { headUrl } = this.userInfo;
      this.api
        .component(HeadUrlEdit, {
          data: {
            headUrl,
          },
        })
        .then(() => {
          this.loadUserInfo();
        });
    },
  },
};
</script>
<style lang="less" scoped>
.views-user-setting {
  .user-head {
    &,
    .ant-avatar {
      width: 100px !important;
      height: 100px !important;
      border-radius: 0;
    }
    .position-absolute {
      display: none;
    }
    &:hover .position-absolute {
      display: block;
      height: 28px;
      width: 100px;
      font-size: 14px;
      margin-top: -28px;
      line-height: 25px;
      background: rgba(0, 0, 0, 0.8);
    }
  }
  .setting-panel {
    border: 1px solid #dbdbdb;
    border-radius: 8px;
    margin: 40px 40px 0 20px;
    background-image: url(../../../public/img/bg-setting.png);
    background-repeat: no-repeat;
    background-size: 500px;
    background-position: top right;
    .item {
      padding: 40px;
      border-bottom: 1px dashed #d8d8d8;
      &:last-child {
        border-bottom: none;
      }
    }
    .icon-xiugaishouji,
    .icon-denglumima,
    .icon-zhuxiao {
      margin-top: -3px;
      margin-right: 5px;
    }
    .setting-icon {
      i {
        margin-right: 8px;
      }
      color: #999999;
    }
  }
}
</style>