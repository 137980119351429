<template>
  <cs-dialog-form
    :title="title"
    :model="formModel"
    :rules="formRules"
    :action="submit"
    width="720"
  >
    <p>从个性头像库中选择一张照片作为头像</p>
    <div class="d-flex">
      <a-row class="img-panel overflow-auto" :gutter="16">
        <a-col :span="6" v-for="(item, index) in source.imgList" :key="index">
          <img
            :src="item"
            :class="{ active: index === activeIndex }"
            @click="selectImgClick(index, item)"
          />
        </a-col>
      </a-row>
      <div class="text-center" style="margin-left: 30px">
        <a-avatar
          :src="formModel.headUrl"
          shape="square"
          style="width: 110px; height: 110px; border-radius: 0"
        />
        <p style="color: #333333; margin-top: 15px">头像预览</p>
      </div>
    </div>
  </cs-dialog-form>
</template>
<script>
export default {
  props: {
    data: {},
  },
  data() {
    return {
      formModel: {
        headUrl: "",
        ...this.data,
      },
      formRules: {},
      source: {
        imgList: [],
      },
      activeIndex: "",
    };
  },
  computed: {
    title() {
      return `修改头像`;
    },
  },
  created() {
    const arr = [];
    for (let i = 1; i <= 8; i++) {
      arr.push(this.api.getHeadUrl(`${i}.png`));
    }
    this.source.imgList = arr;
  },
  methods: {
    selectImgClick(index, item) {
      this.activeIndex = index;
      this.formModel.headUrl = item;
    },
    async submit({ headUrl }) {
      return await this.api.service.uac_oapi_sso_user_updateUserInfo(
        {
          headUrl,
        },
        {}
      );
    },
  },
};
</script>
<style lang="less" scoped>
.img-panel {
  width: 540px;
  margin-left: 0 !important;
  background: #f4f5f6;
  padding: 20px 20px 4px 20px;
  height: 380px;
  .ant-col {
    margin-bottom: 16px;
  }
  img {
    width: 100%;
    background: white;
    border: 2px solid transparent;
    cursor: pointer;
    &.active {
      border: 2px solid rgba(76, 114, 236, 0.71);
    }
  }
}
</style>